import React ,{Component} from "react";
import Api from './../api/index';
import common from './../common/common';
import DocumentTitle from 'react-document-title';
import './zixun.less';

export default class zixun extends Component{
    constructor(prop){
        super(prop);
        this.state = {
            newsDetails:{}
        }
       
    }
    componentWillMount(){
         Api.acitivityDetails(this.props.match.params.id).then(d=>{
             this.setState({
                newsDetails : d.data
             })
         })
    }

   
    render(){
        return (
            <DocumentTitle title='活动'>
                <div className = "acitivity">
                    <ul className = "acitivity-info">
                        <li className = "acitivity-info-top">
                            {this.state.newsDetails.title}
                        </li>
                        <li className = "acitivity-info-bottom">
                            <p>活动状态 : {this.state.newsDetails.status == 1?'有效':'无效'}</p>
                            <p>活动时间 : {common.dateFormat(this.state.newsDetails.start_time)+'-'+common.dateFormat(this.state.newsDetails.end_time)}</p>
                            <p>活动地址 : {this.state.newsDetails.address}</p>
                        </li>
                    </ul>
                    <div className = "news-content">
                        <h4 className="cm-part-title"><span>线下活动</span><span>&nbsp;</span></h4>
                        <div dangerouslySetInnerHTML={{ __html: this.state.newsDetails.info}}></div>
                
                       
                        
                        </div>
                 
                </div>
            </DocumentTitle>
        )
    }
}