import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import './home.less';
import { Carousel, WingBlank,NavBar, Icon } from 'antd-mobile';
// import { __values } from '_tslib@1.9.3@tslib';
import Api from './../api/index';
import common from './../common/common';
import DocumentTitle from 'react-document-title';


export default class home extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            data: [],
            imgHeight: 176,
            offlineActivity:[],
            hotNews:[],
            bannerArray:[],
            headerImg:'',
            flag:false
        }
    }
    componentWillMount(){
        // Api.userInfo().then(d=>{
        //     this.setState({
        //         headerImg:d.data.head_img
        //     })
        // });      
    }
    changeLink(url){
        if(url.indexOf('http')!=-1){
            window.location.href = url;
        }else{
            this.props.history.push(url);
        }
    }
    componentDidMount() {
        // simulate img loading
        setTimeout(() => {
            this.setState({
            data: [require('../assets/img/znc-bg-1.png'), require('../assets/img/znc-bg-2.png')],
            });
        }, 100);

        Api.activityList({status:1,_b:0,_e:9,groups:2}).then(d=>{
            let offlineActivity = [];
            let showNum = 4;
            d.data = [...d.data]
            let pushNumber = d.data.length%showNum == 0? d.data.length/showNum : parseInt(d.data.length/showNum)+1;
            for(let i = 0;i<pushNumber;i++){
              offlineActivity[i] =  d.data.splice(0,showNum);
            }
            this.setState({
                offlineActivity:Object(offlineActivity,[])
            })
        });
        Api.writingsList({_b:1,_e:4,groups:2}).then(d=>{
            let  hotNews = d.data;
            this.setState({
                hotNews:Object(hotNews,[])
            })
        });
        
        Api.banner().then(d=>{
            this.setState({
                bannerArray:d.data
            })
        })
    }
    openLink(value){
        if(value.indexOf('http')!=-1 || value.indexOf('www')!=-1){
            window.location.href = value;
        }
    }
    carouselHtml(){
        return( 
            <div>
              <Carousel
                    autoplay={this.state.flag}
                    infinite
                    >
                    {this.state.bannerArray.map(val => (
                        <img
                            key = {val.img}
                            src={val.img}
                            onClick = {this.openLink.bind(this,val.url)}
                            alt=""
                            style={{ width: '100%', verticalAlign: 'top' }}
                            onLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                            this.setState({ imgHeight: 'auto',flag:true });
                            }}
                        />
                    ))}
                </Carousel>  
            </div>
            );
    }

    linkHtml(){
        const getImage = (id)=>require('./../assets/img/link-'+id+'.png');
        const linkArray = [
            {name:'机器人教育',url:'http://m.school.zhinanche.com/',id:1},
            {name:'在线课堂',url:'http://www.zhinanche.online/',id:2},
            {name:'机器人人才网',url:'http://job.zhinanche.com/mobile/#/baseInfo',id:3},
            {name:'技术服务',url:'/serverZnc',id:4},
        ]

        return (
            <ul className = "home-other">
                {linkArray.map(d=>(
                <li key = {`${d.id}homeOther`} onClick = {this.changeLink.bind(this,d.url)}>
                    <p><img src={require('./../assets/img/link-'+d.id+'.png')}/></p> 
                    <p>{d.name}</p>
                </li>)) }
            </ul>
        )
    }

    // <!-- 服务区域 -->

    baseInfoHtml(){
        return(    
        <div className="base-information">
            <h4 className="cm-part-title">
                <span>全国机器人培训与技术服务网络</span>
                <span onClick = {()=>this.props.history.push('/znc')} > <img src={require("./../assets/img/base-information-r.png")}  className="znc-logo-r" /></span>  
            </h4>
            <img src={require("./../assets/img/base-img.png")}  style={{width: '100%'}}/>
        </div>)
    }

    ttt(value){
        let strHtml = ``;
        for(let i of value){
            strHtml += `<li class="active-content">
                        <div class="activity-img-cont"><img src="${i.picture}" /></div>
                        <p class="van-ellipsis">${i.title}</p>
                        
            </li> `
        }
        return '<ul class = "ttt" style = "display:flex">'+ strHtml + '</ul>';
    }
    underLineHtml(){
        return( 
            
            <div>
                <div className="offline-activity">
                    <h4 className="cm-part-title"><span>线下活动</span><span>&nbsp;</span></h4>
                    <Carousel
                        autoplay={false}
                        infinite
                        beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                        afterChange={index => console.log('slide to', index)}
                        >
                        {this.state.offlineActivity.map((val,index) => {
                            let strHtml = val.map(d=>{
                                return(<li key = {`${d.id}homeOther1`} className="active-content"  onClick = {()=>this.props.history.push(`/zixun/${d.id}`)} >
                                            <div className="activity-img-cont"><img src={d.image_url} /></div>
                                            <p className="title-ellipsis">{d.title}</p>
                                        </li>)
                            })
                            return (
                                <ul key = {index} className = "ttt">{strHtml}</ul>
                            )
                        }
                        )}
                    </Carousel> 
                </div>
            </div>
        );
    }

    latestNews(){
        return(
            <div>
                <div className="latest-news">
                    <h4 className="cm-part-title">最新资讯</h4>
                    <ul className="latest-news-list">
                    {this.state.hotNews.map(d=>(
                        <li key = {`${d.id}newsLast`} onClick = {()=>{this.props.history.push({pathname:`/news/${d.id}`})}}>
                            <p className = "latest-news-title">{d.title}</p>
                            <p className="latest-news-bottom">
                                <span>{common.dateFormat(d.create_time)} 发布</span>
                                <span className="primaryColor">查看详情 </span>
                            </p>
                        </li>
                    )
                        
                    )}
                        
                    </ul>
                </div>
            </div>
        )
    }

   

    render(){
        return(
            <DocumentTitle title='指南车官网首页'>
                <div className = "home-index home">
                    <NavBar
                    mode="light"
                    leftContent={<img className = "znc-logo" src={require("./../assets/img/znc_logo.png")}/>}
                    rightContent={[
                        // <img onClick = {()=>this.props.history.push('/loginIn')} key = '2bar' className="header-icon" src={this.state.headerImg == "" ? require("./../assets/img/header-img-3.png"):this.state.headerImg}/>,
                    ]}
                    > </NavBar>

                    {this.carouselHtml()}
                    {this.linkHtml()}
                    {this.baseInfoHtml()}
                    {this.underLineHtml()}
                    {this.latestNews()}
                </div>
            </DocumentTitle>  
        )
    }
}