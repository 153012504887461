import React,{Component} from "react";
import { List, InputItem, Button,Toast } from 'antd-mobile';
import './loginFrom.less';
import Api from './../api/index';

export default class loginIn extends Component {
    state = {
        username: "",
        password: "",
        value:''
      }
      
      loginIn(){
          Api.login({
            username:this.state.username,
            password:this.state.password,
          }).then(d=>{
              if(d.result == 1){
                localStorage.setItem("schoolToken",d.data);
                Toast.info("登录成功！");
                this.props.history.push('/');                  
              }

          })
      }

      onChange = (value) => {  
        this.setState({
            username:value.replace(/\s+/g,""),
        });
      }
      onChangePasswd = (value) => {  
        this.setState({
            password:value,
        });
    }
      render() {
        return (
          <div className = "form-content">
            <img className = "login-img" src = {require('./../assets/img/znc-login.png')}/>
            <List className = "cm-form" renderHeader={() => '登录指南车'}>
            <InputItem
                type="phone"
                placeholder="请输入您的手机号"
                value={this.state.username}
                onChange={this.onChange}
              ><div style={{ backgroundImage: 'url('+require('./../assets/img/form-icon2.png')+')', backgroundSize: '100% 100%', height: '0.45rem', width: '0.32rem' }} /></InputItem>

               <InputItem
                type="password"
                placeholder="请输入您的密码"
                onChange={this.onChangePasswd}
                value={this.state.password}
              ><div style={{ backgroundImage: 'url('+require('./../assets/img/form-icon1.png')+')', backgroundSize: '100% 100%', height: '0.38rem', width: '0.32rem' }} /></InputItem>  
                <Button  onClick = {this.loginIn.bind(this)} type="primary" style = {{'marginTop':'0.75rem'}}>登录</Button>
               
            </List>
            <ul className = "link-array">
                <li onClick = {()=>this.props.history.push('/register')}>立即注册</li>
                <li onClick = {()=>this.props.history.push('/forgetPasswd')}>忘记密码</li>
            </ul>
          </div>
        );
    }
}