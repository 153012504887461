import React,{Component} from "react";
import { List, InputItem, Button ,Toast} from 'antd-mobile';
import Api from './../api/index';
import './loginFrom.less';

export default class loginIn extends Component {
    state = {
        phone: "",
        code:"",
        password: "",
        rePassword:"",
        codeName:"获取验证码",
        numberCode:60,
      }

      getCode(){
          let pattern = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
          if(!pattern.test(this.state.username)){
            Toast.info('请输入正确的手机号!');
            return ;
          }
          

          let timer = null;
          let _this = this;
          if(_this.state.codeName == "获取验证码"){
            _this.setState({
              codeName : _this.state.numberCode -1 + "秒后重试",
              numberCode : _this.state.numberCode -1
          });
            Api.code({send:this.state.phone}).then(d=>{if(d.result == 1)Toast.info('发送短信成功')})
            timer = setInterval(()=>{
              if(_this.state.numberCode==1){
                _this.setState({
                  codeName : "获取验证码",
                  numberCode : 60
                });
                clearInterval(timer);
              }else{
                _this.setState({
                    codeName : _this.state.numberCode -1 + "秒后重试",
                    numberCode : _this.state.numberCode -1
                })
              }
          
            },1000)
          }else{
            Toast.info("请稍后重试!");
          }
      }

      forgetPassword(){
        // rePassword :(params) =>  http.put(`/user/password/re`,params.params,{headersData:params.headersData}),
        let message = "";
				let pattern = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;

				this.state.username = this.state.phone;
				message = this.state.phone.length==0||this.state.code.length==0||this.state.password.length==0||this.state.rePassword.length==0?"请填写全部！":message;
				message = this.state.password!=this.state.rePassword?"两次密码不一致！":message;
				message = pattern.test(this.state.username)?message:"请填写正确的手机号码！";
				
				if(message.length!=0){
					Toast.info(message);
					return;
        }

        Api.rePassword({
          params:{username:this.state.username,password:this.state.password},
          headersData:{send:this.state.username,code:this.state.code}
        }).then(d=>{
          if(d.result == 1){
            Toast.info("重置成功!");
            Api.login({
              username:this.state.username,
              password:this.state.password,
            }).then(d=>{
                if(d.result == 1){
                  localStorage.setItem("schoolToken",d.data);                  
                }
            })
            this.props.history.push("/");
          }
        })
      }
      changePhone = (value) => {
         this.setState({
          phone:value.replace(/\s+/g,""),
         })
      }
      changeCode = (value) => {
        this.setState({
         code:value
        })
     }
     changePassword = (value) => {
      this.setState({
       password:value
      })
    }
     changeRePassword = (value) => {
      this.setState({
       rePassword:value
      })
    }
      render() {
        return (
          <div className = "form-content">
            <List className = "cm-form" renderHeader={() => ''}>
            <InputItem
                type="phone"
                placeholder="请输入您的手机号"
                value={this.state.phone}
                onChange = {this.changePhone}
              ><div style={{ backgroundImage: 'url('+require('./../assets/img/form-icon2.png')+')', backgroundSize: '100% 100%', height: '0.45rem', width: '0.32rem' }} /></InputItem>
              <InputItem
                type="text"
                placeholder="请输入验证码"
                extra = {this.state.codeName}
                onChange = {this.changeCode}
                onExtraClick= {this.getCode.bind(this)}
                value={this.state.code}
              ><div style={{ backgroundImage: 'url('+require('./../assets/img/form-icon3.png')+')', backgroundSize: '100% 100%', height: '0.38rem', width: '0.32rem' }} /></InputItem>
               <InputItem
                type="password"
                placeholder="请输入您的密码"
                onChange = {this.changePassword}
                value={this.state.password}
              ><div style={{ backgroundImage: 'url('+require('./../assets/img/form-icon1.png')+')', backgroundSize: '100% 100%', height: '0.38rem', width: '0.32rem' }} /></InputItem> 
              <InputItem
                type="password"
                placeholder="请输入您的密码"
                onChange = {this.changeRePassword}
                value={this.state.rePassword}
              ><div style={{ backgroundImage: 'url('+require('./../assets/img/form-icon1.png')+')', backgroundSize: '100% 100%', height: '0.38rem', width: '0.32rem' }} /></InputItem>  
                <Button type="primary" style = {{'marginTop':'0.75rem'}} onClick = {this.forgetPassword.bind(this)}>确定</Button>
               
            </List>
           
          </div>
        );
    }
}