import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import './znc.less';
import Api from './../api/index';
export default class znc extends Component {
    constructor(prop) {
        super(prop);

        this.state = {
            baseList: []
        }
    }
    componentDidMount() {
        Api.getBaseList().then(d => {
            this.setState({
                baseList : d.data
            })
        });
    }
    render() {
        console.log(this.state)
        return (
            <DocumentTitle title='关于我们'>
                <div className='zhinanche'>
                    <div className="model-warp zhinanche-info">
                        <img src={require('./../assets/img/znc_logo.png')} />
                        <p>杭州指南车机器人科技有限公司</p>
                    </div>
                    <div className="model-warp">
                        <div className="m-group-list">
                            <p className="m-group-list-title cm-part-title">公司简介  </p>
                            <aside>
                            杭州指南车机器人科技有限公司成立于2015年，坐落于环境优美的杭州西湖畔，中国（杭州）人工智能小镇5G创新园，与著名的之江实验室毗邻。指南车定位于建立以机器人自动化工程师为核心的智能制造生态系统服务商，以人才服务为切入点，为智能制造企业培养并输送优质机器人自动化工程师，同时为其提供技术服务、供应链服务和工业大数据，构建智能制造生态链服务系统，并开发出基于大数据的智能制造设备维护管理系统，实时监控生产状态并对维保提供支持。全国已建成杭州总部，佛山、泰州、成都等九家分公司及服务中心，目前已向智能制造行业输出近万名工程师，上千家机器人生产商和集成商提供服务，业务遍及全国。
                            </aside>
                        </div>
                    </div>


                    <div className="model-warp">
                        <div className="m-group-list">
                            <p className="m-group-list-title cm-part-title"> 联系我们 </p>
                            <ul className="m-result-list">
                                {this.state.baseList.map((d, index) => (
                                    <li key={index}>
                                        <h4>{d.name}</h4>
                                        <p>电话：{d.mobile}</p>
                                        <p>地址：{d.address}</p>
                                        <p>邮箱：{d.email}</p>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>



                </div>
            </DocumentTitle>
        )
    }
}