import React ,{Component} from "react";
import Api from './../api/index';
import common from './../common/common';
import DocumentTitle from 'react-document-title';
import './news.less';

export default class news extends Component{
    constructor(prop){
        super(prop);
        this.state = {
            newsDetails:{}
        }
       
    }
    componentWillMount(){
         Api.newsDetails(this.props.match.params.id).then(d=>{
             this.setState({
                newsDetails : d.data
             })
         })
    }
    render(){
        return (
            <DocumentTitle title='资讯'>
                <div className = "news">
                    <ul className = "news-info">
                        <li className = "news-info-top">
                            {this.state.newsDetails.title}
                        </li>
                        <li className = "news-info-bottom">
                            <span>信息来源 : {this.state.newsDetails.origin}</span>
                            <span>发布时间 : {common.dateFormat(this.state.newsDetails.create_time)}</span>
                        </li>
                    </ul>
                    <div className = "news-content" dangerouslySetInnerHTML={{ __html: this.state.newsDetails.info}}  >
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}