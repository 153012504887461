import http from "./http.js";

export default {
                /**
    *
    获取活动列表
    *
    @method activityList get
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */


   activityList :(params) =>  http.get(`/activity`,{params:params}),
    /**
    *
    获取公司动态列表
    *
    @method writingsList get
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   writingsList :(params) =>  http.get(`/news`,{params:params}),

       /**
    *
    获取新闻详情
    *
    @method writingsList get
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   newsDetails :(id) =>  http.get(`/news/${id}`),
       /**
    *
    获取新闻详情
    *
    @method writingsList get
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   acitivityDetails :(id) =>  http.get(`/activity/${id}`),
       /**
    *
    获取banner图
    *
    @method banner get
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]*/
   banner:()=>http.get(`/common/banner/9`),

   /**
    *注册
    请求接口:/user/register
    *
    @method GET
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   register :(params) =>  http.post(`/user/register`,params.params,{headersData:params.headersData}),

      /**
    *登录
    请求接口:/user/login
    *
    @method GET
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   login :(params) =>  http.post(`/user/login`,params),

         /**
    *忘记密码(重置密码)
    请求接口:/user/password/re
    *
    @method GET
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   rePassword :(params) =>  http.put(`https://test.zhinanche.com/api/v2/zhinanche-main/user/password/re`,params.params,{headersData:params.headersData}),

           /**
    *修改密码
    请求接口:/user/password
    *
    @method GET
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   password :(params) =>  http.put(`/user/password`,params),
   
   share:(search)=>http.get(`https://test.zhinanche.com/api/v2/zhinanche-online/weixin/sign`,{params:search}),

   
     /**
    请求手机短信
    *
    @method map
    *
    @return {object} 请求处理回调[then(function)]
    */   
   code:(params)=>http.post("/common/sms/send",params),

   
                 /**
    *获取用户信息 
    请求接口:/user/info
    *
    @method GET
    *
    [@param] {object} params 
    *
    @return {object} 请求处理回调[then(function)]
    */
   userInfo :() =>  http.get(`/user/info`,{configToken:true}),

   /**
    * 获取指南车基地信息
    * get  
    */
   getBaseList:() => http.get(`/train/base`),
}