import React, { Component } from 'react';
import logo from './logo.svg';
import wx from 'weixin-js-sdk';
import Api from './api/index';
import './App.less';

import Routers from './router/';
import DocumentTitle from 'react-document-title';
class App extends Component {
  constructor(prop){
    super(prop);
    // Api.share({url:encodeURIComponent(window.location.href.split('#')[0])}).then(d=>{
    //   wx.config({
    //     debug: false,
    //     appId: d.data.app_id, // 和获取Ticke的必须一样------必填，公众号的唯一标识
    //     timestamp:d.data.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: d.data.noncestr, // 必填，生成签名的随机串
    //     signature: d.data.sign,// 必填，签名，见附录1
    //     //需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
    //     jsApiList: [
    //       'onMenuShareAppMessage','onMenuShareTimeline',
    //       'onMenuShareQQ','onMenuShareQZone'
    //       // ,'updateAppMessageShareData','updateTimelineShareData'
    //     ],
    //      success: function (res) {
    //     },
    //     fail: function (res) {
    //     }
    //   }); 
    // })
  }
  render() {
    return (
      <DocumentTitle title='Home'>
      <div className="App">
        <Routers></Routers>
      </div></DocumentTitle>
    );
  }
}

export default App;
