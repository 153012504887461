import React, { Component } from 'react';
import './serverZnc.less';
export default class serverZnc extends Component {

    render() {
        return (
            <div className='server-znc'>
                <div className="model-warp zhinanche-info">
                    <img src={require('./../assets/img/znc_logo.png')} />
                    <p>杭州指南车机器人科技有限公司</p>
                </div>
                <div className="cm-wirte">
                    <p className="m-group-list-title cm-part-title"><span><img src={require("./../assets/img/title-img.png")} alt="" />公司简介</span>   </p>
                    <p>
                    杭州指南车机器人科技有限公司成立于2015年，坐落于环境优美的杭州西湖畔，中国（杭州）人工智能小镇5G创新园，与著名的之江实验室毗邻。指南车定位于建立以机器人自动化工程师为核心的智能制造生态系统服务商，以人才服务为切入点，为智能制造企业培养并输送优质机器人自动化工程师，同时为其提供技术服务、供应链服务和工业大数据，构建智能制造生态链服务系统，并开发出基于大数据的智能制造设备维护管理系统，实时监控生产状态并对维保提供支持。全国已建成杭州总部，佛山、泰州、成都等九家分公司及服务中心，目前已向智能制造行业输出近万名工程师，上千家机器人生产商和集成商提供服务，业务遍及全国。
                    </p>
                    <div className="img-warp"><img src={require("./../assets/img/company_2.png")} alt="" /></div>

                    <p><span>业务介绍</span>：解决您的机器人生产线故障问题；</p>
                    <p><span>售后服务</span>：机器人保养、机器人紧急故障处理、机器人配件销售；</p>
                    <p><span>技术支持</span>：机器人项目调试、PLC调试、项目改造、技术服务（系统升级、加选项）；</p>
                    <p><span>定制培训</span>：根据客户需求提供定制化上门培训；</p>
                    <div className="img-warp"><img src={require("./../assets/img/company_3.png")} alt="" /></div>
                    <p><span>四重保障（一）：应对及时</span></p>
                    <p> 全国性服务网络</p>
                    <p> 指南车在全国建立了多个线下机器人技术服务站，基本覆盖全国主  要制造业集中区，分别位于杭州、佛山、泰州、成都、苏州、宁  波、洛阳、芜湖、宝鸡、上海，基本覆盖全国；</p>
                    <p>服务中心覆盖范围区域服务响应时间为4小时。</p>
                    <div className="img-warp"><img src={require("./../assets/img/base-img.png")} alt="" /></div>
                    <p><span>四重保障（二）：专业、专注，技术人员实力强</span></p>
                    <p>专家：指南车拥有近20余人的机器人专家团队坐镇，均来自于原厂或知名机器人集成商，机器人售后、调试项目经验相当丰富。</p>
                    <p>服务工程师：指南车平台拥有近千名认证机器人工程师，分散于全国各地。</p>
                    <p>指南车 APP：上万名全职与兼职工程师在线，随时匹配服务需求。</p>
                    <div className="img-warp"><img src={require("./../assets/img/company_5.png")} alt="" /></div>
                    <p><span>四重保障（三）：专业化服务体系、标准化的服务流程。</span></p>
                    <p>指南车拥有极具竞争力的机器人配件渠道，主推原厂正规渠道全新  备件，同时提供高性价比的新品拆机，二手拆机供客户选择并提供 6个月到1年不等质保承诺，售后有保障。价格相比较原厂，有极大的优势。</p>
                    <div className="img-warp"><img src={require("./../assets/img/company_6.png")} alt="" /></div>
                    <p><span>四重保障（四）： 专业化服务体系、标准化的服务流程。</span></p>
                    <p>专业且齐全的工具室及机器人配件仓库，基于指南车 APP提供全流 程、在线、透明、标准专业化的技术服务。</p>
                    <div className="img-warp"><img src={require("./../assets/img/company_4.png")} alt="" /></div>
                </div>
                <div className="cm-wirte server-connect">
                    <p className="m-group-list-title cm-part-title"> <span><img src={require("./../assets/img/title-img.png")} alt="" /> 联系我们</span>  </p>
                    <div>
                        {/* <p><span>全国服务热线：18711326338（周先生）</span></p> */}
                        <p><span>副总经理：张鹏     手机：130 4666 6378</span></p>
                        <p><span>上海区域经理：李小露     手机：189 6917 9663</span></p>
                        <p><span>浙江、安徽区域经理：杨飞飞    手机：185 0156 1049</span></p>
                        <p><span>江苏、山东区域经理：方凯    手机：188 5599 4376</span></p>
                        <p><span>总部地址：杭州市余杭区文一西路1818-1号中国（杭州）5G创新园418室</span></p>

                        <div className="img-warp">
                            <ul>
                                <li>
                                    <p>指南车APP</p>
                                    <img src={require("./../assets/img/erweima_1.png")} alt="" />

                                </li>
                                <li>
                                    <p>指南车企业版</p>
                                    <img src={require("./../assets/img/erweima_2.jpg")} alt="" />

                                </li>
                            </ul>

                        </div>



                    </div>
                </div>





            </div>
        )
    }
}