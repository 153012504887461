import axios from "axios";
import {Toast } from 'antd-mobile';


let AUTH_TOKEN = (function () {
  return localStorage.getItem("token");
})();

var instance = axios.create({
 // baseURL: 'http://test.zhinanche.com/api/v2/zhinanche-main', //反向代理
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
  },
     baseURL: 'https://api.zhinanche.com/v2/zhinanche-main'
});

instance.interceptors.request.use(function (config) {
    let url = config.url;
    let zncToken = localStorage.getItem("schoolToken");

    if(config.hasOwnProperty("headersData")){
      for(let i in config.headersData)config.headers[i] = config.headersData[i];
    }

    if(config.hasOwnProperty("configToken"))config.headers.token = zncToken;
    if(config.hasOwnProperty("contentJson")){
      config.headers["Content-Type"] =  "application/json";
      config.dataType = 'json'
    }
    if (url.indexOf("img") == -1 && !config.hasOwnProperty("contentJson")) {
      config.transformRequest = [function (data) {
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
    }];

  }
  return config;
}, function (err) {

  return Promise.reject(err);
});
instance.interceptors.response.use(function (res) {
  if(res.data.hasOwnProperty("code")&&res.data.code!=0){

  }
  return res.data;
}, function (err) {
    let errObj = err.response;
    Toast.info(errObj.data.errors.message);
    
  return err;
});


export default instance;
