import React, {Component} from 'react';

import {HashRouter as Router,Route} from 'react-router-dom';

import home from './../component/home';
import zixun from './../component/zixun';
import znc from './../component/znc';
import news from './../component/news';
import loginIn from './../component/loginIn';
import forgetPasswd from './../component/forgetPasswd';
import register from './../component/register';
import serverZnc from './../component/server-znc';

export default class routers extends Component {
    render(){
        return(
            <Router>
                <div>
                    <Route path='/' exact={true}  component={home} ></Route>
                    <Route path='/zixun/:id' component={zixun} ></Route>
                    <Route path='/znc'  component={znc} ></Route>
                    <Route path='/news/:id'  component={news} ></Route>
                    <Route path='/loginIn'  component={loginIn} ></Route>
                    <Route path='/forgetPasswd'  component={forgetPasswd} ></Route> 
                    <Route path='/register'  component={register} ></Route> 
                    <Route path='/serverZnc'  component={serverZnc} ></Route> 
                    
                </div>
            </Router>                 
        )

    }

}
